<template>
  <PageContentLayoutOnly>
    <ModernTable
      :filters="filters"
      :rows="budgetAccountsTableList"
      :columns="columns"
      :pagination="pagination"
    >
      <template v-slot:header>
        <div class="level">
          <div class="level-left level-item title">{{ $t('budgetAccounts.title') }}</div>
          <div class="level-right" :style="{ display: 'flex', alignItems: 'center' }">
            <div class="level-item" :style="{ marginBottom: 0 }">
              <svg
                width="30"
                height="30"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7"
                ></path>
              </svg>
            </div>
            <div class="level-item">
              <b-button label="Back to Budget Builder" @click.prevent="returnToParent()" />
            </div>
          </div>
        </div>
        <div class="subtitle">{{ budgetName || '' }}</div>

        <nav class="level" :style="{ marginBottom: '12px' }">
          <span>
            <b-button
              tests-id="modal-budgetaccount-button"
              @click="createBudgetAccountModal()"
              size="is-small"
              type="is-primary"
              rounded
            >
              Create Budget Account </b-button
            ><span class="pl-3"></span>
            <b-button @click="viewBudgetSummaryReport()" size="is-small" type="is-primary" rounded>
              Budget Summary Report </b-button
            ><span class="pl-3"></span>
            <b-button @click="viewBudgetDetailReport()" size="is-small" type="is-primary" rounded>
              Budget Detail Report
            </b-button></span
          >
          >
          <b-button
            type="is-ghost"
            @click="toggleFilters"
            :style="styles.filterButton"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </nav>
      </template>
    </ModernTable>

    <div class="pt-5 box" style="background-color: #f8f9fa;" v-if="totals && totals != undefined">
      <div class="columns">
        <div class="font-bold column is-one-fifth"></div>
        <div class="font-bold column is-one-fifth"></div>
        <div class="font-bold column is-one-fifth has-text-right">
          Budgeted
        </div>
        <div class="font-bold column is-one-fifth has-text-right">
          Avg Per Month
        </div>
        <div class="font-bold column is-one-fifth has-text-right">
          Avg Unit / Month
        </div>
      </div>
      <div class="columns">
        <div class="font-bold column is-one-fifth"></div>
        <div class="font-bold column is-one-fifth has-text-right">Total Expense:</div>
        <div class="column is-one-fifth has-text-right">
          {{ `${totals.currentYearTotalExpense || 0}` | currency }}
        </div>
        <div class="column is-one-fifth has-text-right">
          {{ `${totals.currentYearTotalExpensePerMonth || 0}` | currency }}
        </div>
        <div class="column is-one-fifth has-text-right">
          {{ `${totals.currentYearTotalExpensePerUnitPerMonth || 0}` | currency }}
        </div>
      </div>
      <div class="columns">
        <div class="font-bold column is-one-fifth"></div>
        <div class="font-bold column is-one-fifth has-text-right">Total Income:</div>
        <div class="column is-one-fifth has-text-right">
          {{ `${totals.currentYearTotalIncome || 0}` | currency }}
        </div>
        <div class="column is-one-fifth has-text-right">
          {{ `${totals.currentYearTotalIncomePerMonth || 0}` | currency }}
        </div>
        <div class="column is-one-fifth has-text-right">
          {{ `${totals.currentYearTotalIncomePerUnitPerMonth || 0}` | currency }}
        </div>
      </div>
      <div class="columns">
        <div class="font-bold column is-one-fifth"></div>
        <div class="font-bold column is-one-fifth has-text-right">Total Assessed Income:</div>
        <div class="column is-one-fifth has-text-right">
          {{ `${totals.currentYearTotalAssessedIncome || 0}` | currency }}
        </div>
        <div class="column is-one-fifth has-text-right">
          {{ `${totals.currentYearTotalAssessedIncomePerMonth || 0}` | currency }}
        </div>
        <div class="column is-one-fifth has-text-right">
          {{ `${totals.currentYearTotalAssessedIncomePerUnitPerMonth || 0}` | currency }}
        </div>
      </div>
    </div>

    <Modal :toggle.sync="toggle">
      <BudgetAccount
        :toggle="toggle"
        :budgetID="parseInt(budgetId)"
        :budgetAccount="selectedBudgetAccount"
      >
      </BudgetAccount>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import Modal from '@/components/Modal'
import BudgetAccount from '@/components/Accounting/BudgetAccount'

import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'BudgetAccounts',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    Modal,
    BudgetAccount
  },

  data,
  methods,

  computed: {
    ...mapGetters('budgets', ['budgetAccountsList', 'budgetAccountTotals']),
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    hoaId() {
      this.reload()
    },

    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { budgetAccounts: { title: 'Budget Accounts' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

@media screen and (max-width: $tablet) {
  .filter-button {
    display: none;
  }
}

div.amountFrom {
  width: 60px !important;
}

.filter-button {
  color: #838383 !important;

  &.active {
    color: $link !important;
  }
}

.button.is-ghost {
  background: none;
  border-color: transparent;
  color: #7957d5;
  text-decoration: none;
}
</style>
